// frontend/src/components/IdeaSubmission.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import {
  Container,
  TextField,
  Button,
  Box,
  CircularProgress,
  Paper,
} from '@mui/material';
import { submitIdea } from '../services/api';
import SendIcon from '@mui/icons-material/Send';

function IdeaSubmission() {
  const [idea, setIdea] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    
    try {
        const response = await submitIdea(idea);
        if (response && (response.idea_id || response.id)) {
            const ideaId = response.idea_id || response.id;
            if (!isAuthenticated) {
                localStorage.setItem('pendingIdeaId', ideaId);
                navigate('/login');
            } else {
                navigate(`/progress/${ideaId}`);
            }
        } else {
            console.error('No id in response:', response);
        }
    } catch (error) {
        if (error.message === 'UNAUTHORIZED') {
            navigate('/login');
        } else {
            console.error('Failed to submit idea:', error);
        }
    } finally {
        setIsSubmitting(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 6, mb: 8 }}>
      <Paper 
        elevation={3}
        sx={{
          p: 4,
          borderRadius: 2,
          background: 'rgba(36, 36, 51, 0.95)',
          backdropFilter: 'blur(10px)',
        }}
      >
        <Box textAlign="center" mb={4}>
          <img 
            src="/logo.png" 
            alt="Hatoi.ai - Has Anyone Thought Of It"
            style={{
              width: '100%',
              maxWidth: '400px',
              height: 'auto',
              marginBottom: '2rem'
            }}
          />
        </Box>
        <TextField
          placeholder="Type your idea here..."
          multiline
          rows={6}
          variant="outlined"
          fullWidth
          value={idea}
          onChange={(e) => setIdea(e.target.value)}
          margin="normal"
          sx={{
            '& .MuiOutlinedInput-root': {
              backgroundColor: 'rgba(26, 26, 36, 0.6)',
            },
          }}
        />
        <Box textAlign="center" mt={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!idea.trim() || isSubmitting}
            startIcon={isSubmitting ? <CircularProgress size={20} /> : <SendIcon />}
            sx={{
              minWidth: 200,
              py: 1.5,
              fontSize: '1.1rem',
            }}
          >
            {isSubmitting ? 'Analyzing...' : 'Submit Idea'}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}

export default IdeaSubmission;