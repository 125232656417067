import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Button, Tabs, Tab, CircularProgress, Switch, FormControlLabel, LinearProgress } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAuth } from '../contexts/AuthContext';

const getAuthHeaders = () => {
  const token = localStorage.getItem('token');
  return token ? { 'Authorization': `Bearer ${token}` } : {};
};

// Add color scheme logic at the top
const scoreColorScheme = {
  success: {
    border: '#2e7d32',
    bg: 'rgba(46, 125, 50, 0.08)',
    text: '#fff',
    boxBg: '#2e7d32'
  },
  warning: {
    border: '#ed6c02',
    bg: 'rgba(237, 108, 2, 0.08)',
    text: '#fff',
    boxBg: '#ed6c02'
  },
  error: {
    border: '#d32f2f',
    bg: 'rgba(211, 47, 47, 0.08)',
    text: '#fff',
    boxBg: '#d32f2f'
  }
};

const getScoreColor = (score) => {
  if (score >= 80) return 'success';
  if (score >= 60) return 'warning';
  return 'error';
};

function IdeaProgress() {
  const { ideaId } = useParams();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [idea, setIdea] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [buildPlan, setBuildPlan] = useState(null);
  const [buildPlanExists, setBuildPlanExists] = useState(false);
  const [isGeneratingPlan, setIsGeneratingPlan] = useState(false);
  const [isAnalyzing, setIsAnalyzing] = useState(true);
  const [showOriginal, setShowOriginal] = useState(false);

  // Fetch progress effect
  useEffect(() => {
    if (!ideaId) {
      setError('No idea ID provided');
      setLoading(false);
      return;
    }

    const fetchProgress = async () => {
      try {
        const response = await fetch(`/api/ideas/progress/${ideaId}`);
        if (response.ok) {
          const data = await response.json();
          setIdea(data);
          const status = data.status?.toLowerCase();

          if (status === 'rejected') {
            setError(data.summary);
            setIsAnalyzing(false);
            return;
          }

          setIsAnalyzing(status === 'processing' || status === 'queued');

          if (data.build_plan) {
            setBuildPlan(data.build_plan);
            setBuildPlanExists(true);
          }
        } else {
          const errorData = await response.json();
          setError(`Error: ${errorData.detail || 'Failed to fetch idea'}`);
        }
      } catch (error) {
        console.error('Error fetching idea progress:', error);
        setError(`Error: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchProgress();
    const interval = setInterval(fetchProgress, 5000);

    return () => clearInterval(interval);
  }, [ideaId]);


  // Update user_id effect
  useEffect(() => {
    if (isAuthenticated && ideaId) {
      const updateIdeaUser = async () => {
        try {
          const response = await fetch(`/api/ideas/${ideaId}/user`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              ...getAuthHeaders()
            }
          });
          
          if (!response.ok) {
            console.error('Failed to update idea user');
          }
        } catch (error) {
          console.error('Error updating idea user:', error);
        }
      };

      updateIdeaUser();
    }
  }, [isAuthenticated, ideaId]);

  const handleBuildPlan = async () => {
    if (buildPlanExists) {
      setActiveTab(2);
      return;
    }

    setIsGeneratingPlan(true);
    try {
      const response = await fetch(`/api/ideas/${ideaId}/build-plan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        setBuildPlan(data);
        setBuildPlanExists(true);
        setActiveTab(2);
      } else {
        throw new Error('Failed to generate build plan');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to generate build plan. Please try again.');
    } finally {
      setIsGeneratingPlan(false);
    }
  };

  // Show error if there is one
  if (error) {
    return (
      <Box>
        <Button
          onClick={() => navigate('/dashboard')}
          startIcon={<ArrowBackIcon />}
          sx={{ mb: 3 }}
          variant="outlined"
        >
          Back to Dashboard
        </Button>
        
        <Paper 
          elevation={3} 
          sx={{ 
            p: { xs: 3, sm: 4 },
            borderRadius: 3,
            bgcolor: 'background.paper',
            border: '1px solid',
            borderColor: 'divider',
            textAlign: 'center'
          }}
        >
          <Typography 
            variant="h6" 
            color="primary.main"
            gutterBottom
            sx={{ mb: 2 }}
          >
            {idea?.title || 'Invalid Idea'}
          </Typography>
          
          <Typography 
            variant="body1" 
            color="text.secondary"
            sx={{ mb: 3 }}
          >
            We couldn't process this submission as it doesn't appear to be a concrete idea or innovation.
          </Typography>
          
          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ 
              fontSize: '0.9rem',
              bgcolor: 'background.default',
              p: 2,
              borderRadius: 1
            }}
          >
            Tip: Try submitting a specific product, service, or innovation idea. Include details about what it does and how it works.
          </Typography>
        </Paper>
      </Box>
    );
  }

  // Render loading state
  if (loading || !idea) {
    return (
      <Box sx={{ width: '100%', mt: 4 }}>
        <Typography align="center" gutterBottom>
          Loading idea progress...
        </Typography>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Button
        onClick={() => navigate('/dashboard')}
        startIcon={<ArrowBackIcon />}
        sx={{ mb: 3 }}
        variant="outlined"
      >
        Back to Dashboard
      </Button>

      {/* Main content in tabs for better organization */}
      <Tabs 
        value={activeTab} 
        onChange={(e, v) => setActiveTab(v)}
        sx={{ mb: 3 }}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="🔍 Analysis" />
        <Tab label="📊 Details" />
        {idea?.score >= 50 && (
          <Tab 
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                🛠️ Build Plan
                {isGeneratingPlan && <CircularProgress size={16} sx={{ ml: 1 }} />}
              </Box>
            }
            onClick={!buildPlan ? handleBuildPlan : undefined}
          />
        )}
        {idea?.score >= 75 && (
          <Tab 
            label="💰 Sell Idea"
          />
        )}
      </Tabs>

      {/* Show loading state for analysis */}
      {isAnalyzing && (
        <Paper elevation={3} sx={{ p: 3, mb: 3, bgcolor: 'background.paper' }}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            gap: 2,
            my: 4 
          }}>
            <CircularProgress size={40} />
            <Typography variant="h6" color="text.secondary">
              Analyzing your idea...
            </Typography>
            <Typography color="text.secondary" sx={{ textAlign: 'center' }}>
              Our AI is carefully evaluating your idea. This usually takes about a minute.
            </Typography>
          </Box>
        </Paper>
      )}

      {activeTab === 0 && !isAnalyzing && (
        <Paper 
          elevation={3} 
          sx={{ 
            p: { xs: 3, sm: 4 },
            borderRadius: 3,
            bgcolor: 'background.paper',
            border: '1px solid',
            borderColor: 'divider'
          }}
        >
          {/* Toggle for Original Content */}
          <Box sx={{ mb: 3 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={showOriginal}
                  onChange={(e) => setShowOriginal(e.target.checked)}
                  color="primary"
                />
              }
              label="Show Original Submission"
            />
          </Box>

          {/* Original Content Section */}
          {showOriginal && (
            <Box sx={{ mb: 4 }}>
              <Typography 
                variant="h6" 
                color="primary.main"
                gutterBottom
                sx={{
                  fontSize: { xs: '1.2rem', sm: '1.4rem' },
                  fontWeight: 600
                }}
              >
                Original Submission
              </Typography>
              <Paper
                elevation={0}
                sx={{
                  p: 2,
                  bgcolor: 'background.default',
                  borderRadius: 2,
                  border: '1px solid',
                  borderColor: 'divider'
                }}
              >
                <Typography 
                  variant="body1"
                  sx={{ 
                    whiteSpace: 'pre-wrap',
                    fontSize: { xs: '0.875rem', sm: '1rem' },
                    lineHeight: 1.6
                  }}
                >
                  {idea?.content || 'No content available'}
                </Typography>
              </Paper>
            </Box>
          )}

          {/* Summary Section */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' },
            gap: 4,
            alignItems: { xs: 'center', md: 'flex-start' },
            justifyContent: 'flex-start'
          }}>
            <Box sx={{ flex: 1 }}>
              <Typography 
                variant="h6" 
                color="primary.main"
                gutterBottom
                sx={{
                  fontSize: { xs: '1.2rem', sm: '1.4rem' },
                  fontWeight: 600,
                  textAlign: 'center'
                }}
              >
                Summary
              </Typography>
              <Typography 
                variant="body1"
                sx={{ whiteSpace: 'pre-wrap' }}
              >
                {idea.summary}
              </Typography>
            </Box>

            {/* Image Section */}
            {idea?.image_url && (
              <Box sx={{ 
                width: { xs: '100%', md: '250px' },
                minWidth: { md: '250px' },
                ml: { md: 'auto' },
                flexShrink: 0
              }}>
                <Box
                  sx={{
                    width: '100%',
                    aspectRatio: '1',
                    borderRadius: 2,
                    overflow: 'hidden',
                    border: '1px solid',
                    borderColor: 'divider',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'background.default',
                  }}
                >
                  <img 
                    src={idea.image_url} 
                    alt={idea.title || "Idea illustration"}
                    loading="lazy"
                    onError={(e) => {
                      console.error(`Failed to load image: ${idea.image_url}`, e);
                      e.target.style.display = 'none';
                      e.target.nextSibling.style.display = 'block';
                    }}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                  <Typography 
                    variant="h1" 
                    sx={{ 
                      fontSize: { xs: '3rem', sm: '4rem' },
                      display: 'none'
                    }}
                  >
                    🧠
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>

          {/* Score Section - Updated styling */}
          {idea.score !== null && (
            <Box sx={{ textAlign: 'center', mt: 4 }}>
              <Box
                sx={{ 
                  py: 1.5,
                  px: 4,
                  borderRadius: 2,
                  bgcolor: scoreColorScheme[getScoreColor(idea.score)].boxBg,
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&:hover': {
                    boxShadow: 2,
                    filter: 'brightness(1.1)'
                  }
                }}
              >
                <Typography 
                  sx={{ 
                    fontWeight: 600,
                    color: '#fff',
                    fontSize: { xs: '1.2rem', sm: '1.4rem' },
                    letterSpacing: '0.5px'
                  }}
                >
                  Hatoi Score: {idea.score}/100
                </Typography>
              </Box>
            </Box>
          )}
        </Paper>
      )}

      {activeTab === 1 && !isAnalyzing && (
        <Paper elevation={3} sx={{ p: 3 }}>
          {/* Analysis Sections */}
          {[
            { title: 'Originality', content: idea.originality },
            { title: 'Market Potential', content: idea.market_potential },
            { title: 'Technical Feasibility', content: idea.technical_feasibility },
            { title: 'Recommendations', content: idea.recommendations }
          ].map((section, index) => (
            section.content && (
              <Box key={index} sx={{ mb: 4 }}>
                <Typography 
                  variant="h6" 
                  color="primary"
                  gutterBottom
                >
                  {section.title}
                </Typography>
                <Typography sx={{ whiteSpace: 'pre-wrap' }}>
                  {section.content}
                </Typography>
              </Box>
            )
          ))}
        </Paper>
      )}

      {activeTab === 2 && idea?.score >= 50 && (
        <Paper elevation={3} sx={{ p: 3 }}>
          {!buildPlan && !isGeneratingPlan ? (
            <Box sx={{ textAlign: 'center', py: 4 }}>
              <Typography variant="h6" gutterBottom>
                Ready to build your idea?
              </Typography>
              <Typography color="text.secondary" sx={{ mb: 3 }}>
                Get a detailed plan with tech stack recommendations and next steps.
              </Typography>
              <Button
                variant="contained"
                onClick={handleBuildPlan}
                startIcon={isGeneratingPlan ? <CircularProgress size={20} /> : null}
              >
                Generate Build Plan
              </Button>
            </Box>
          ) : buildPlan ? (
            <Box sx={{ p: 3 }}>
              <Typography variant="h6" color="primary.main" gutterBottom>
                MVP Scope
              </Typography>
              <Typography sx={{ mb: 4 }}>
                {buildPlan.mvp_scope}
              </Typography>

              <Typography variant="h6" color="primary.main" gutterBottom>
                Tech Stack
              </Typography>
              {buildPlan.tech_stack && (
                <Box sx={{ display: 'grid', gap: 2, mb: 4 }}>
                  {buildPlan.tech_stack.frontend?.length > 0 && (
                    <Box>
                      <Typography variant="subtitle1" fontWeight={600}>Frontend</Typography>
                      <Box component="ul" sx={{ mt: 1, mb: 2 }}>
                        {buildPlan.tech_stack.frontend.map((tech, i) => (
                          <Typography component="li" key={i}>{tech}</Typography>
                        ))}
                      </Box>
                    </Box>
                  )}
                  {buildPlan.tech_stack.backend?.length > 0 && (
                    <Box>
                      <Typography variant="subtitle1" fontWeight={600}>Backend</Typography>
                      <Box component="ul" sx={{ mt: 1, mb: 2 }}>
                        {buildPlan.tech_stack.backend.map((tech, i) => (
                          <Typography component="li" key={i}>{tech}</Typography>
                        ))}
                      </Box>
                    </Box>
                  )}
                  {buildPlan.tech_stack.database?.length > 0 && (
                    <Box>
                      <Typography variant="subtitle1" fontWeight={600}>Database</Typography>
                      <Box component="ul" sx={{ mt: 1, mb: 2 }}>
                        {buildPlan.tech_stack.database.map((tech, i) => (
                          <Typography component="li" key={i}>{tech}</Typography>
                        ))}
                      </Box>
                    </Box>
                  )}
                  {buildPlan.tech_stack.infrastructure?.length > 0 && (
                    <Box>
                      <Typography variant="subtitle1" fontWeight={600}>Infrastructure</Typography>
                      <Box component="ul" sx={{ mt: 1, mb: 2 }}>
                        {buildPlan.tech_stack.infrastructure.map((tech, i) => (
                          <Typography component="li" key={i}>{tech}</Typography>
                        ))}
                      </Box>
                    </Box>
                  )}
                </Box>
              )}

              <Typography variant="h6" color="primary.main" gutterBottom sx={{ mt: 4 }}>
                Next Steps
              </Typography>
              <Box component="ul" sx={{ mt: 1, mb: 2 }}>
                {buildPlan.next_steps.map((step, i) => (
                  <Typography component="li" key={i}>{step}</Typography>
                ))}
              </Box>

              <Typography variant="h6" color="primary.main" gutterBottom sx={{ mt: 4 }}>
                Estimated Timeline
              </Typography>
              <Typography>
                {buildPlan.estimated_timeline}
              </Typography>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
              <CircularProgress />
            </Box>
          )}
        </Paper>
      )}

      {activeTab === 3 && idea?.score >= 75 && (
        <Paper elevation={3} sx={{ p: 3 }}>
          <Box sx={{ textAlign: 'center', py: 4 }}>
            <Typography variant="h6" gutterBottom>
              Coming Soon!
            </Typography>
            <Typography color="text.secondary">
              The marketplace for selling ideas is under development.
            </Typography>
          </Box>
        </Paper>
      )}
    </Box>
  );
}

export default IdeaProgress;