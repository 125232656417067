import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Button,
  Paper
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { formatDistanceToNow } from 'date-fns';
import { formatCurrency } from '../utils/format';
import { getButtonText, normalizeStatus, STATUS } from '../utils/status';

// Color scheme logic based on score
const scoreColorScheme = {
  success: {
    border: '#2e7d32',
    bg: 'rgba(46, 125, 50, 0.08)',
    text: '#fff',
    boxBg: '#2e7d32'
  },
  warning: {
    border: '#ed6c02',
    bg: 'rgba(237, 108, 2, 0.08)',
    text: '#fff',
    boxBg: '#ed6c02'
  },
  error: {
    border: '#d32f2f',
    bg: 'rgba(211, 47, 47, 0.08)',
    text: '#fff',
    boxBg: '#d32f2f'
  },
  default: {
    border: '#9e9e9e',
    bg: 'rgba(158, 158, 158, 0.08)',
    text: '#fff',
    boxBg: '#757575'
  }
};

const getScoreColor = (score) => {
  if (score >= 80) return 'success';
  if (score >= 60) return 'warning';
  return 'error';
};

function IdeaCard({ idea, onDelete, onImageClick }) {
  const colorScheme = idea.score !== null && idea.score !== undefined
    ? scoreColorScheme[getScoreColor(idea.score)]
    : scoreColorScheme.default;

  const normalizedStatus = normalizeStatus(idea.status);
  const isCompleted = normalizedStatus === STATUS.COMPLETED;

  return (
    <Card 
      elevation={2}
      sx={{ 
        mb: 2,
        borderRadius: 2,
        position: 'relative',
        overflow: 'visible',
        '&:hover': {
          boxShadow: 6,
          transform: 'translateY(-2px)',
          transition: 'all 0.2s ease-in-out'
        }
      }}
    >
      <Box 
        sx={{ 
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          width: '4px',
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
          bgcolor: colorScheme.border,
        }} 
      />
      <CardContent sx={{ pl: 3 }}>
        {/* Title and Score Row */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
          <Typography variant="h6" component="h2" sx={{ flex: 1 }}>
            {idea.title || idea.content}
          </Typography>
          <Box
            sx={{ 
              py: 0.75,
              px: 2,
              borderRadius: 2,
              bgcolor: colorScheme.boxBg,
              display: 'inline-flex',
              alignItems: 'center',
              flexShrink: 0,
              '&:hover': {
                boxShadow: 2,
                filter: 'brightness(1.1)'
              }
            }}
          >
            <Typography 
              sx={{ 
                fontWeight: 600,
                color: colorScheme.text,
                fontSize: { xs: '0.875rem', sm: '1rem' },
                letterSpacing: '0.5px'
              }}
            >
              Score: {idea.score ?? 0}/100
            </Typography>
          </Box>
        </Box>

        {/* Meta Info Row */}
        <Box 
          sx={{ 
            display: 'flex', 
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: 2,
            mb: 3
          }}
        >
          <MetaInfo>Status: {idea.status}</MetaInfo>
          {idea.cost !== undefined && (
            <MetaInfo>Cost: {formatCurrency(idea.cost)}</MetaInfo>
          )}
          {idea.created_at && (
            <MetaInfo>
              {formatDistanceToNow(new Date(idea.created_at), { addSuffix: true })}
            </MetaInfo>
          )}
          {idea.source && (
            <MetaInfo>Source: {idea.source}</MetaInfo>
          )}
        </Box>

        {/* Summary and Image Row */}
        {idea.summary && (
          <Box 
            sx={{ 
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: 2,
              mb: 2,
              alignItems: 'stretch',
              height: { xs: 'auto', sm: '150px' }
            }}
          >
            <Paper 
              elevation={0}
              sx={{ 
                p: 2,
                flex: 1,
                bgcolor: 'background.default',
                borderRadius: 2,
                border: '1px solid',
                borderColor: 'divider',
                overflow: 'auto',
                minHeight: { xs: '150px', sm: 'auto' }
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontSize: { xs: '0.875rem', sm: '1rem' },
                  lineHeight: 1.6,
                  color: 'text.primary'
                }}
              >
                {idea.summary}
              </Typography>
            </Paper>

            <ImageThumbnail 
              imageUrl={idea.image_url} 
              title={idea.title} 
              onClick={() => onImageClick(idea.image_url)}
            />
          </Box>
        )}

        {/* Actions */}
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'flex-end',
            gap: 1,
            borderTop: 1,
            borderColor: 'divider',
            pt: 2,
            mt: 2
          }}
        >
          <IconButton
            onClick={() => onDelete(idea.idea_id, Boolean(idea.source))}
            color="error"
            size="small"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
          <Button
            component={Link}
            to={`/progress/${idea.idea_id}`}
            variant={isCompleted ? 'contained' : 'outlined'}
            size="small"
            sx={{
              borderRadius: 2,
              textTransform: 'none',
              px: 3,
              opacity: isCompleted ? 1 : 0.7,
              '&:hover': {
                transform: isCompleted ? 'translateY(-1px)' : 'none',
                boxShadow: isCompleted ? 2 : 0
              }
            }}
          >
            {getButtonText(idea.status)}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}

// Helper components
const MetaInfo = ({ children }) => (
  <Typography 
    color="textSecondary"
    sx={{ 
      fontSize: { xs: '0.875rem', sm: '1rem' },
      display: 'flex',
      alignItems: 'center',
      '&::after': {
        content: '""',
        display: { xs: 'none', sm: 'block' },
        width: '4px',
        height: '4px',
        borderRadius: '50%',
        bgcolor: 'divider',
        ml: 2
      }
    }}
  >
    {children}
  </Typography>
);

const ImageThumbnail = ({ imageUrl, title, onClick }) => (
  <Box
    onClick={onClick}
    sx={{
      width: { xs: '100%', sm: '150px' },
      height: { xs: '120px', sm: '150px' },
      borderRadius: 2,
      overflow: 'hidden',
      border: '1px solid',
      borderColor: 'divider',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      bgcolor: 'background.default',
      cursor: 'pointer',
      transition: 'transform 0.2s',
      '&:hover': {
        transform: 'scale(1.02)'
      }
    }}
  >
    {imageUrl ? (
      <img 
        src={imageUrl} 
        alt={title || "Idea illustration"}
        loading="lazy"
        onError={(e) => {
          console.error(`Failed to load image: ${imageUrl}`, e);
          e.target.style.display = 'none';
          e.target.nextSibling.style.display = 'block';
        }}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          transition: 'transform 0.3s ease'
        }}
      />
    ) : null}
    <Typography 
      variant="h1" 
      sx={{ 
        fontSize: { xs: '3rem', sm: '4rem' },
        display: imageUrl ? 'none' : 'block'
      }}
    >
      🧠
    </Typography>
  </Box>
);

export default IdeaCard; 