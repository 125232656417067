import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  Button,
  Skeleton
} from '@mui/material';

const scoreColorScheme = {
  success: {
    border: '#2e7d32',
    bg: 'rgba(46, 125, 50, 0.08)',
    text: '#fff',
    boxBg: '#2e7d32'
  },
  warning: {
    border: '#ed6c02',
    bg: 'rgba(237, 108, 2, 0.08)',
    text: '#fff',
    boxBg: '#ed6c02'
  },
  error: {
    border: '#d32f2f',
    bg: 'rgba(211, 47, 47, 0.08)',
    text: '#fff',
    boxBg: '#d32f2f'
  }
};

const goldStyle = {
  border: '#FFD700',
  bg: 'rgba(255, 215, 0, 0.08)',
  text: '#fff',
  boxBg: '#FFD700',
  cardBg: 'rgba(255, 215, 0, 0.03)',
  cardBorder: 'rgba(255, 215, 0, 0.3)',
  cardShadow: '0 8px 16px rgba(255, 215, 0, 0.15)',
  buttonBg: '#FFD700',
  buttonHover: '#F7C400'
};

const getScoreColor = (score) => {
  if (score >= 80) return 'success';
  if (score >= 60) return 'warning';
  return 'error';
};

const getMedalEmoji = (index) => {
  switch(index) {
    case 0: return '🏆';
    case 1: return '🥈';
    case 2: return '🥉';
    default: return null;
  }
};

function FeaturedIdeas() {
  const [ideas, setIdeas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchFeatured = useCallback(async (retries = 3) => {
    try {
      const response = await fetch('/api/ideas/featured');
      if (response.ok) {
        const data = await response.json();
        const sorted = data.ideas.sort((a, b) => {
          const scoreA = Number(a.score) || 0;
          const scoreB = Number(b.score) || 0;
          if (scoreB !== scoreA) {
            return scoreB - scoreA;
          }
          return new Date(b.created_at) - new Date(a.created_at);
        });
        setIdeas(sorted);
        setError(null);
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching featured ideas:', error);
      setError(error.message);
      
      if (retries > 0) {
        setTimeout(() => {
          fetchFeatured(retries - 1);
        }, 1000);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchFeatured();
  }, [fetchFeatured]);

  if (loading) {
    return (
      <Paper sx={{ p: 3, mb: 4, borderRadius: 2 }}>
        <Skeleton variant="text" width="30%" height={40} />
        <Skeleton variant="text" width="100%" height={80} />
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3, mt: 3 }}>
          {[1, 2, 3].map((i) => (
            <Box key={i} sx={{ flex: '1 1 300px', minWidth: 0 }}>
              <Skeleton variant="rectangular" height={200} />
            </Box>
          ))}
        </Box>
      </Paper>
    );
  }

  if (error) {
    return null;
  }

  if (!ideas?.length) return null;

  return (
    <Paper 
      elevation={2}
      sx={{ 
        p: { xs: 2, sm: 3 }, 
        mb: 4,
        borderRadius: 2,
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'visible',
      }}
    >
      <Box 
        sx={{ 
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          width: '4px',
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
          bgcolor: goldStyle.buttonBg,
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'linear-gradient(to bottom, rgba(255,215,0,0.8), rgba(255,215,0,0.4))'
          }
        }} 
      />
      
      <Box sx={{ 
        display: 'grid',
        gridTemplateColumns: {
          xs: '1fr',
          sm: 'repeat(auto-fit, minmax(300px, 1fr))',
          lg: 'repeat(auto-fit, minmax(350px, 1fr))'
        },
        gap: 3,
        '& > :first-of-type': {
          gridColumn: '1 / -1',
          maxWidth: { lg: '1200px' },
          mx: 'auto'
        }
      }}>
        {ideas?.map((idea, index) => (
          <Box key={idea.idea_id}>
            <Paper 
              elevation={2}
              sx={{ 
                p: { xs: 2, sm: 3 },
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 2,
                bgcolor: 'background.paper',
                ...(index === 0 && {
                  display: 'grid',
                  gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
                  gap: 3,
                  p: 3,
                  bgcolor: goldStyle.cardBg,
                  border: `1px solid ${goldStyle.cardBorder}`,
                  boxShadow: goldStyle.cardShadow,
                  '&:hover': {
                    boxShadow: `0 12px 24px rgba(255, 215, 0, 0.25)`,
                    transform: 'translateY(-2px)',
                    transition: 'all 0.2s ease-in-out'
                  }
                }),
                ...(!index && {
                  '&:hover': {
                    boxShadow: 6,
                    transform: 'translateY(-2px)',
                    transition: 'all 0.2s ease-in-out'
                  }
                })
              }}
            >
              {idea.image_url && (
                <Box
                  sx={{
                    width: '100%',
                    height: index === 0 ? 250 : 150,
                    mb: 2,
                    borderRadius: 1,
                    overflow: 'hidden',
                    border: '1px solid',
                    borderColor: index === 0 ? goldStyle.cardBorder : 'divider',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'background.default'
                  }}
                >
                  {idea.image_url ? (
                    <img 
                      src={idea.image_url} 
                      alt={idea.title}
                      loading="lazy"
                      onError={(e) => {
                        console.error(`Failed to load image: ${idea.image_url}`);
                        e.target.parentElement.innerHTML = '🧠';
                      }}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        transition: 'transform 0.3s ease'
                      }}
                    />
                  ) : (
                    <Typography variant="h1" sx={{ fontSize: '4rem' }}>
                      🧠
                    </Typography>
                  )}
                </Box>
              )}
              <Typography 
                variant="h6" 
                gutterBottom 
                sx={{ 
                  fontWeight: 600,
                  fontSize: { xs: '1rem', sm: '1.1rem' },
                  minHeight: { xs: '2.4em', sm: 'auto' },
                  display: '-webkit-box',
                  WebkitLineClamp: index === 0 ? { xs: 2, md: 3 } : 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  ...(index <= 2 && {
                    color: goldStyle.border,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  })
                }}
              >
                {getMedalEmoji(index) && (
                  <Typography 
                    component="span" 
                    sx={{ 
                      fontSize: { xs: '1.2rem', sm: '1.4rem' },
                      lineHeight: 1,
                      opacity: index === 0 ? 1 : 0.8
                    }}
                  >
                    {getMedalEmoji(index)}
                  </Typography>
                )}
                {idea.title}
              </Typography>
              <Typography 
                sx={{ 
                  color: 'text.secondary',
                  flexGrow: 1,
                  mb: 2,
                  fontSize: '0.95rem',
                  display: '-webkit-box',
                  WebkitLineClamp: index === 0 ? { xs: 3, md: 8 } : 3,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  ...(index === 0 && {
                    fontSize: { md: '1rem' },
                    lineHeight: { md: 1.7 }
                  })
                }}
              >
                {idea.summary}
              </Typography>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between',
                alignItems: 'center',
                mt: 'auto',
                pt: 2,
                borderTop: '1px solid',
                borderColor: index === 0 ? goldStyle.cardBorder : 'divider'
              }}>
                <Box
                  sx={{ 
                    py: 0.75,
                    px: 2,
                    borderRadius: 2,
                    bgcolor: index === 0 ? goldStyle.boxBg : scoreColorScheme[getScoreColor(idea.score)].boxBg,
                    display: 'inline-flex',
                    alignItems: 'center',
                    '&:hover': {
                      boxShadow: 2,
                      filter: 'brightness(1.1)'
                    }
                  }}
                >
                  <Typography sx={{ 
                    color: index === 0 ? '#000' : scoreColorScheme[getScoreColor(idea.score)].text,
                    fontWeight: 600,
                    fontSize: { xs: '0.875rem', sm: '1rem' },
                    letterSpacing: '0.5px'
                  }}>
                    Score: {idea.score}/100
                  </Typography>
                </Box>
                <Button 
                  component={Link}
                  to={`/progress/${idea.idea_id}`}
                  variant="contained"
                  size="small"
                  sx={{ 
                    borderRadius: 2,
                    textTransform: 'none',
                    px: 3,
                    bgcolor: index === 0 ? goldStyle.buttonBg : 'primary.main',
                    color: index === 0 ? '#000' : 'primary.contrastText',
                    '&:hover': {
                      bgcolor: index === 0 ? goldStyle.buttonHover : 'primary.dark',
                      boxShadow: 2
                    }
                  }}
                >
                  View Details
                </Button>
              </Box>
            </Paper>
          </Box>
        ))}
      </Box>
    </Paper>
  );
}

export default FeaturedIdeas; 