import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import {
  Container,
  Typography,
  Box,
  Stack,
  Tabs,
  Tab,
  CircularProgress,
  Select,
  FormControl,
  MenuItem,
  Modal,
  Backdrop,
  Fade,
} from '@mui/material';
import FeaturedIdeas from './FeaturedIdeas';
import IdeaCard from './IdeaCard';

function Dashboard() {
  const [tab, setTab] = useState(0);
  const [userIdeas, setUserIdeas] = useState([]);
  const [scrapedIdeas, setScrapedIdeas] = useState([]);
  const [allUserIdeas, setAllUserIdeas] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState('date');
  const sortOrder = 'desc';
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }
  }, [isAuthenticated, navigate]);

  const fetchIdeas = async () => {
    if (!isAuthenticated) {
      return;
    }

    setLoading(true);
    try {
      // Always fetch user's own ideas
      const userResponse = await fetch(`/api/ideas/user/${user.id}`);
      if (userResponse.ok) {
        const userData = await userResponse.json();
        setUserIdeas(userData);
      }

      // Only fetch admin data if user.id === 1
      if (user.id === 1) {
        const scrapedResponse = await fetch('/api/ideas/scraped');
        if (scrapedResponse.ok) {
          const scrapedData = await scrapedResponse.json();
          setScrapedIdeas(scrapedData);
        }

        const allIdeasResponse = await fetch('/api/ideas/all');
        if (allIdeasResponse.ok) {
          const allIdeasData = await allIdeasResponse.json();
          setAllUserIdeas(allIdeasData);
        }
      }
    } catch (error) {
      console.error('Error fetching ideas:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchIdeas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isAuthenticated]);

  const handleDelete = async (ideaId, isScraped = false) => {
    const message = isScraped 
      ? 'Are you sure you want to delete this scraped idea? This action cannot be undone.'
      : 'Are you sure you want to delete this idea? This action cannot be undone.';

    if (!window.confirm(message)) {
      return;
    }

    try {
      const response = await fetch(`/api/ideas/${ideaId}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        // Refresh all ideas after successful deletion
        await fetchIdeas();
      } else {
        const data = await response.json();
        alert(data.detail || 'Failed to delete idea');
      }
    } catch (error) {
      console.error('Error deleting idea:', error);
      alert('Error deleting idea');
    }
  };

  const sortIdeas = useCallback((ideas, sortBy, sortOrder) => {
    return [...ideas].sort((a, b) => {
      let comparison = 0;
      switch (sortBy) {
        case 'score':
          comparison = (b.score || 0) - (a.score || 0);
          break;
        case 'date':
          comparison = new Date(b.created_at) - new Date(a.created_at);
          break;
        default:
          comparison = 0;
      }
      return sortOrder === 'asc' ? -comparison : comparison;
    });
  }, []);

  const IdeasList = ({ ideas }) => (
    <>
      <Box sx={{ mb: 2 }}>
        <FormControl size="small">
          <Select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <MenuItem value="date">Most Recent</MenuItem>
            <MenuItem value="score">Highest Score</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {sortIdeas(ideas, sortBy, sortOrder).map(idea => (
        <IdeaCard 
          key={idea.idea_id}
          idea={idea}
          onDelete={(ideaId, isScraped) => handleDelete(ideaId, isScraped)}
          onImageClick={handleImageClick}
        />
      ))}
    </>
  );

  const UserIdeasSection = () => (
    <IdeasList ideas={userIdeas} />
  );

  const ScrapedIdeasSection = () => (
    <IdeasList ideas={scrapedIdeas} />
  );

  const AllIdeasSection = () => (
    <IdeasList ideas={allUserIdeas} />
  );

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleCloseImage = () => {
    setSelectedImage(null);
  };

  const ImageLightbox = () => (
    <Modal
      open={!!selectedImage}
      onClose={handleCloseImage}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={!!selectedImage}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '90vw',
            maxHeight: '90vh',
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 1,
          }}
        >
          <img
            src={selectedImage}
            alt="Enlarged view"
            style={{
              maxWidth: '100%',
              maxHeight: '85vh',
              objectFit: 'contain'
            }}
          />
        </Box>
      </Fade>
    </Modal>
  );

  if (loading) {
    return (
      <Container sx={{ mt: 4, textAlign: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs 
          value={tab} 
          onChange={(e, newValue) => setTab(newValue)}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="My Ideas" />
          {isAuthenticated && <Tab label="Featured Ideas" />}
          {user?.id === 1 && <Tab label="Scraped Ideas" />}
          {user?.id === 1 && <Tab label="All Users' Ideas" />}
        </Tabs>
      </Box>

      <Stack spacing={2}>
        {tab === 0 && (
          <>
            <UserIdeasSection />
            {userIdeas.length === 0 && (
              <Box>
                <Typography align="center">No ideas submitted yet.</Typography>
              </Box>
            )}
          </>
        )}

        {tab === 1 &&  (
          <FeaturedIdeas />
        )}

        {tab === 2 && user?.id === 1 && (
          <ScrapedIdeasSection />
        )}

        {tab === 3 && user?.id === 1 && (
          <>
            <AllIdeasSection />
            {allUserIdeas.length === 0 && (
              <Box>
                <Typography align="center">No ideas from other users.</Typography>
              </Box>
            )}
          </>
        )}
      </Stack>

      <ImageLightbox />
    </Container>
  );
}

export default Dashboard; 