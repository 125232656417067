export const STATUS = {
  QUEUED: 'queued',
  PROCESSING: 'processing',
  COMPLETED: 'completed',
  REJECTED: 'rejected',
  FAILED: 'failed'
};

export const normalizeStatus = (status) => {
  return (status || '').toLowerCase().trim();
};

export const getButtonText = (status) => {
  const normalizedStatus = normalizeStatus(status);
  return normalizedStatus === STATUS.COMPLETED ? 'View Details' : 'View Progress';
}; 