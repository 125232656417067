import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  AppBar, 
  Toolbar, 
  Button, 
  Box, 
  IconButton, 
  Tooltip,
  Menu,
  Typography,
  useTheme,
  useMediaQuery,
  Container,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';

function AppLayout({ children }) {
  const navigate = useNavigate();
  const { user, isAuthenticated, logout } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleMenuClose();
    navigate('/');
  };

  const handleForceScrape = async () => {
    try {
      const response = await fetch('/api/ideas/scrape', {
        method: 'POST',
      });
      if (response.ok) {
        alert('Scraping started!');
      }
    } catch (error) {
      console.error('Error forcing scrape:', error);
      alert('Failed to start scraping');
    }
    handleMenuClose();
  };

  const MenuItems = () => (
    <>
      <Button 
        color="inherit" 
        onClick={() => navigate('/')}
      >
        Submit Idea
      </Button>
      {isAuthenticated && (
        <>
          <Button 
            color="inherit" 
            onClick={() => navigate('/dashboard')}
          >Dashboard
          </Button>
          {user?.id === 1 && (
            <Tooltip title="Force Scrape">
              <IconButton 
                color="inherit" 
                onClick={handleForceScrape}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
      {!isAuthenticated ? (
        <>
          <Button 
            color="inherit"
            onClick={() => navigate('/login')}
          >
            Login
          </Button>
          <Button 
            color="inherit"
            onClick={() => navigate('/register')}
          >Register
          </Button>
        </>
      ) : (
        <Button 
          color="inherit"
          onClick={handleLogout}
        >Logout
        </Button>
      )}
    </>
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppBar position="static">
        <Toolbar>
          <Typography component={Link} to="/" sx={{ flexGrow: 1, textDecoration: 'none', color: 'inherit' }}>
            🧠💡
          </Typography>
          {isMobile ? (
            <Box>
              <IconButton
                color="inherit"
                onClick={handleMenuOpen}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                PaperProps={{
                  sx: {
                    mt: 5,
                    backgroundColor: 'background.paper',
                    minWidth: 200,
                  }
                }}
              >
                <MenuItems />
              </Menu>
            </Box>
          ) : (
            <MenuItems />
          )}
        </Toolbar>
      </AppBar>

      <Container component="main" sx={{ mt: 4, mb: 4, flex: 1 }}>
        {children}
      </Container>

      <Box component="footer" sx={{ py: 3, px: 2, mt: 'auto', backgroundColor: 'background.paper' }}>
        <Container maxWidth="sm">
          <Typography variant="body2" color="text.secondary" align="center">
            © {new Date().getFullYear()} Hatoi
          </Typography>
        </Container>
      </Box>
    </Box>
  );
}

export default AppLayout; 