// frontend/src/theme.js

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#5CFFE7', // The bright turquoise from the logo
      contrastText: '#1A1A24',
      dark: '#4DEBD4',  // Slightly darker for hover/active states
      light: '#7FFFF0'  // Lighter for backgrounds/highlights
    },
    secondary: {
      main: '#FF5C8D',  // Complementary pink for actions like "Sell Idea"
      dark: '#EB4D7E',
      light: '#FF7CA3'
    },
    background: {
      default: '#1A1A24', // Dark background color
      paper: '#242433',   // Slightly lighter for cards/papers
    },
    text: {
      primary: '#FFFFFF',
      secondary: 'rgba(255, 255, 255, 0.7)',  // More consistent with MUI defaults
      disabled: 'rgba(255, 255, 255, 0.5)'
    },
    status: {
      success: '#4CAF50',
      warning: '#FFC107',
      error: '#F44336'
    }
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: '2.5rem'
    },
    h2: {
      fontWeight: 600,
      fontSize: '2rem'
    },
    h3: {
      fontWeight: 600,
      fontSize: '1.75rem'
    },
    h4: {
      fontWeight: 600,
      fontSize: '1.5rem'
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.25rem'
    },
    h6: {
      fontWeight: 600,
      fontSize: '1rem'
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.7
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.6
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          fontWeight: 600,
          padding: '10px 24px',
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: 'primary.dark',
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 3
      },
      styleOverrides: {
        root: {
          borderRadius: 12,
          border: '1px solid',
          borderColor: 'rgba(255, 255, 255, 0.1)'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.23)',
            },
            '&:hover fieldset': {
              borderColor: 'primary.main',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'primary.main',
            },
          },
        },
      },
    },
  },
});

export default theme;